import { template as template_86b830bff8c94906802c657a2a8a1d4c } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_86b830bff8c94906802c657a2a8a1d4c(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_86b830bff8c94906802c657a2a8a1d4c(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_86b830bff8c94906802c657a2a8a1d4c(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
