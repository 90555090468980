import { template as template_f82ba0e345e247b6878e827c1c4a1625 } from "@ember/template-compiler";
const SidebarSectionMessage = template_f82ba0e345e247b6878e827c1c4a1625(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
